<template>
  <div>
    <b-card-code title="Add Offer" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="active"
                rules="required"
              >
                <b-form-group
                  label="Active"
                  label-for="active"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="active"
                    v-model="activeSelect"
                    :state="activeSelect == null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="activeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
                <b-form-group
                  label="Type"
                  label-for="type"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="type"
                    v-model="typeSelect"
                    :state="typeSelect == null ? false : true"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Amount" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  rules="required|integer"
                >
                  <b-form-input
                    min="1"
                    id="amount"
                    type="number"
                    v-model="offerData.amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter amount"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="User Numbers" label-for="User-Numbers">
                <validation-provider
                  #default="{ errors }"
                  name="user numbers"
                  rules="required|integer"
                >
                  <b-form-input
                    min="1"
                    id="User-Numbers"
                    type="number"
                    v-model="offerData.usernumbers"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter user numbers"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="start date"
                rules="required"
              >
                <b-form-group
                  label="Start Date"
                  label-for="start date"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="offerData.startdate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="end date"
                rules="required"
              >
                <b-form-group
                  label="End date"
                  label-for="end date"
                  :state="errors.length > 0 ? false : null"
                >
                  <flat-pickr
                    v-model="offerData.enddate"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {
    BCardCode,
    flatPickr,
  },
  mixins: [heightTransition],
  data() {
    return {
      id: 0,
      errors_back: [],
      showDismissibleAlert: false,
      required,
      integer,
      offerData: [],
      typeOptions: [
        {
          text: 'Fixed Price',
          value: 'fixed_price',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
      ],
      typeSelect: {
        text: '',
        value: '',
      },
      activeOptions: [
        {
          text: 'True',
          value: '1',
        },
        {
          text: 'False',
          value: '0',
        },
      ],
      activeSelect: {
        text: '',
        value: '',
      },
    }
  },
  methods: {
    // submit offer API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.offerData.type = this.typeSelect.value
          this.offerData.active = this.activeSelect.value
          const data = {}
          data.type = this.offerData.type
          data.amount = this.offerData.amount
          data.startdate = this.offerData.startdate
          data.enddate = this.offerData.enddate
          data.active = this.offerData.active
          data.usernumbers = this.offerData.usernumbers
          axios
            .put('offers/' + this.id, data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    removeItem(index) {
      if (this.offerData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.offerData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    repeateAgain() {
      this.offerData.translations.push({})
      this.tranLocaleSelect.push()
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.id = this.$route.params.id
    axios
      .get('offers/' + this.id)
      .then((result) => {
        this.offerData = result.data.data

        if (this.offerData.active == true) {
          this.activeSelect.text = 'True'
          this.activeSelect.value = '1'
        } else {
          this.activeSelect.text = 'False'
          this.activeSelect.value = '0'
        }
        if (this.offerData.type == 'percentage') {
          this.typeSelect.text = 'Percentage'
          this.typeSelect.value = 'percentage'
        } else {
          this.typeSelect.text = 'Fixed Price'
          this.typeSelect.value = 'fixed_price'
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
// REPETED FORM
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
